#root {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: black;
  img {
    filter: invert(1);
    height: 80vmin;
  }
}

body {
  margin: 0;
}